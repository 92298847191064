const data = {
    role_03982288a12740938fe5059ec3b70728: [
        {
            id: 'digital',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Contenido digital',
            to: `/second-menu`,
            subs: [
                {
                    id: 'acquireAccount',
                    icon: 'simple-icon-paper-plane',
                    label: 'Adquirir cuenta',
                    to: `/app/acquireAccount`,
                },
                {
                    id: 'account',
                    icon: 'simple-icon-paper-plane',
                    label: 'Cuenta',
                    to: `/app/account`,
                },
                {
                    id: 'accountType',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tipo cuenta',
                    to: `/app/accountType`,
                },
                {
                    id: 'accountAvailable',
                    icon: 'simple-icon-paper-plane',
                    label: 'Cuentas disponibles',
                    to: `/app/accountAvailable`,
                },
                {
                    id: 'accountBalance',
                    icon: 'iconsminds-financial',
                    label: 'Saldo contenido digital',
                    to: `/app/accountBalance`,
                },
            ],
        },
    ],
};
export default data;
