const data = {
    role_395ed4cbb89849d8a7a2cafa177af75e: [
        {
            id: 'digital',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Contenido digital',
            to: `/second-menu`,
            subs: [
                {
                    id: 'acquireAccount',
                    icon: 'simple-icon-paper-plane',
                    label: 'Listado de Cuentas',
                    to: `/app/acquireAccount/listAssignedAccounts`,
                },
                {
                    id: 'account',
                    icon: 'simple-icon-paper-plane',
                    label: 'Cuenta',
                    to: `/app/account`,
                },
                {
                    id: 'accountType',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tipo cuenta',
                    to: `/app/accountType`,
                },
                {
                    id: 'accountAvailable',
                    icon: 'simple-icon-paper-plane',
                    label: 'Cuentas disponibles',
                    to: `/app/accountAvailable`,
                },
                {
                    id: 'accountBalance',
                    icon: 'iconsminds-financial',
                    label: 'Saldo contenido digital',
                    to: `/app/accountBalance`,
                },
            ],
        },
    ],
};
export default data;
